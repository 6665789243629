.policies {
  min-height: 100vh;
  width: 100vw;
  background: var(--backgroundColor);
  padding: 2rem;
  display: flex;
  justify-content: center;
}

.policies__box {
  position: relative;
  width: 60%;
  background-color: var(--boxColor);
  text-align: center;
  border-radius: 15px;
}

.policies__goHome {
  position: absolute;
  color: #fff;
  top: 2rem;
  left: 2rem;
  cursor: pointer;
  text-decoration: none;
  font-size: 0.9rem;
}

.policies__edit {
  position: absolute;
  color: #fff;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
  text-decoration: none;
  font-size: 0.9rem;
}

.policies__title {
  border-radius: 15px 15px 0 0;
  padding: 5rem;
  text-transform: uppercase;
  background: var(--secondaryBackgroundColor);
  font-size: 2rem;
  font-weight: 300;
  color: var(--textColor);
}

.policies__content {
  padding: 2rem;
  text-align: start;
}

.policies__content img {
  max-width: 100%;
}

.policies__item {
  text-align: start;
  font-size: 0.95rem;
  margin-top: 1rem;
}

@media screen and (max-width: 768px) {
  .policies__goHome {
    font-size: 0.7rem;
  }

  .policies__edit {
    font-size: 0.7rem;
  }

  .policies__title {
    font-size: 1.5rem;
  }

  .policies {
    padding: 1rem;
    font-size: 0.75rem;
  }

  .policies__box {
    width: 100%;
  }

  .policies__content {
    padding: 0.5rem 1rem;
  }
}
