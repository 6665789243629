.reservation {
  height: 75vh;
  background: var(--backgroundColor);
  display: flex;
  justify-content: center;
  align-items: center;
}

.reservation__box {
  height: 90%;
  width: 80%;
  background: var(--boxColor);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.reservation__title {
  text-transform: uppercase;
  color: var(--titleColor);
  margin: 1rem 0 0 0;
}

.reservation__form {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reservation__formDiv {
  width: 90%;
  display: flex;
  justify-content: space-around;
}

.reservation__formField {
  height: 2.2rem;
  width: 25rem;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.reservation__formField > div {
  width: 100%;
  height: 100%;
  border: none;
  background: var(--inputColor);
}

.reservation__formField > label {
  margin: 0 10px;
  font-size: 0.8rem;
  font-weight: 500;
  min-width: 6rem;
}

.reservation__formField > label > span {
  color: var(--requiredColor);
}

.reservation__formField > input,
.reservation__formField > select {
  width: 100%;
  height: 100%;
  border: none;
  background: var(--inputColor);
  padding: 0.4rem 1rem;
  border-radius: 4px;
}

.reservation__calendar {
  width: 285px;
  height: 2.2rem;
  border: none;
  background: var(--inputColor);
  padding: 0 1rem;
  border-radius: 4px;
  font-size: 0.8rem;
}

.reservation__formField > input[type="radio"] {
  height: 50%;
  width: 1rem;
  margin-right: 0.2rem;
}

.reservation__formField > span {
  margin-right: 1rem;
  font-size: 0.8rem;
}

.reservation__btn {
  margin-top: 1rem;
  height: 2.8rem;
  width: 8rem;
  font-size: 0.9rem;
  font-weight: 600;
  border: 1px solid var(--saveBtnColor);
  color: var(--saveBtnColor);
}

.reservation__btn:hover {
  background: var(--saveBtnColor);
  color: var(--textColor);
}

.reservation__Guests {
  margin: 0 1rem 0 0 !important;
}

.reservation__formField > textarea {
  width: 100%;
  margin-top: 4.5rem;
  outline-width: 0;
  background: var(--inputColor);
  border: none;
  border-radius: 5px;
  padding: 1rem;
}

.reservation__bookedFrom {
  margin-top: 0.7rem;
}

/* small screens */
@media screen and (max-width: 1280px) {
  .reservation__formField {
    height: 1.8rem;
    width: 22rem;
  }

  .reservation__formField > label {
    font-size: 0.7rem;
    min-width: 4rem;
  }

  .reservation__formField > span {
    margin-right: 0.5rem;
    font-size: 0.6rem;
  }

  .reservation__formField > input,
  .reservation__formField > select {
    padding: 0 0.8rem;
    font-size: 0.7rem;
  }

  .reservation__calendar {
    width: 205px;
    height: 1.8rem;
  }

  ::placeholder {
    font-size: 0.8rem;
  }

  .reservation__formField > textarea {
    padding: 0.3rem;
  }

  .reservation__bookedFrom {
    margin-top: 1.3rem;
  }
}

/* Tablets */
@media screen and (max-width: 768px) {
  .reservation__box {
    justify-content: flex-start;
  }

  .reservation__formDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .reservation__formField {
    height: 2rem;
    width: 25rem;
    margin-bottom: 1.1rem;
  }

  .reservation__formField > label {
    font-size: 0.8rem;
    min-width: 5rem;
  }

  .reservation__formField > span {
    margin-right: 0.5rem;
    font-size: 0.8rem;
  }

  .reservation__formField > input,
  .reservation__formField > select {
    font-size: 0.7rem;
  }

  .reservation__calendar {
    width: 268px;
    height: 1.8rem;
    font-size: 0.7rem;
  }

  .reservation__formField > textarea {
    padding: 1rem;
  }

  .reservation__btn {
    margin: 4.5rem 0 1rem;
    height: 2rem;
    width: 6.5rem;
    font-size: 0.8rem;
    font-weight: 600;
    color: var(--saveBtnColor);
  }
}

/* Mobile */
@media screen and (max-width: 480px) {
  .reservation__box {
    width: 90%;
  }

  .reservation__formField {
    width: 18rem;
  }

  .reservation__formField > label {
    font-size: 0.7rem;
    min-width: 3.8rem;
  }

  .reservation__formField > span {
    margin-right: 0.15rem;
    font-size: 0.7rem;
  }

  .reservation__calendar {
    width: 150px;
  }

  .add_guest > span {
    font-size: 0.8rem;
    font-weight: 400;
  }

  .add_guest > input {
    padding: 0.8rem;
    width: 41px;
  }
}

@media screen and (max-width: 320px) {
  .reservation {
    height: 71vh;
  }

  .reservation__formField {
    width: 16rem;
  }

  .reservation__calendar {
    width: 140px;
  }

  .reservation__Guests {
    margin: 0 0.5rem 0 0 !important;
  }

  .add_guest > span {
    font-size: 0.7rem;
    font-weight: 500;
  }

  .add_guest > input {
    padding: 0.4rem;
    width: 27px;
  }
}
