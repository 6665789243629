.modal {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background: rgb(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.addPaymentModal {
  height: 40%;
  width: 20%;
  background: var(--boxColor);
  border-radius: var(--borderRadius);
  padding: 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.addPaymentModal__cancelBtn {
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 20px;
  cursor: pointer;
}

.addPaymentModal__title {
  color: var(--smsBtnColor);
  font-weight: 400;
  font-size: 1.4rem;
  margin-bottom: 3rem;
  text-align: center;
}

.addPaymentModal__amount {
  width: 100%;
  height: 1rem;
  margin: 0.5rem 0;
  padding: 1rem;
}

.addPaymentModal__sendBtn {
  display: block;
  min-width: 10rem;
  margin-top: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--smsBtnColor);
  border: 1px solid var(--smsBtnColor);
}

.addPaymentModal__sendBtn:hover {
  background: var(--smsBtnColor);
  color: var(--textColor);
}
