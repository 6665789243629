.salary__body {
  height: 90%;
}

.salary__year {
  width: 40%;
}

.salary__year > div {
  display: inline-block;
  width: 150px;
  margin-left: 20px;
}

.salary__months {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0rem 1rem 1rem 1rem;
}

.salary__labelGroup {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  justify-content: space-around;
}

.salary__labelGroup > label {
  width: 150px;
  font-weight: 600;
  color: var(--titleColor);
}

.salary__itemGroup {
  display: flex;
  align-items: center;
  height: 2rem;
  margin-top: 1rem;
  justify-content: space-between;
}

.salary__itemGroup > span {
  width: 200px;
}

.salary__paid {
  flex: 2;
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.salary__paid > input {
  margin-right: 5px;
}

.salary__itemGroup > input {
  flex: 3;
  height: 100%;
  border-radius: 4px;
  border: 1px solid var(--tableColor);
  padding-left: 1rem;
  margin-right: 1rem;
}

.salary__save {
  margin-top: 1rem;
  height: 2.8rem;
  width: 8rem;
  font-size: 0.9rem;
  font-weight: 600;
  border: 1px solid var(--saveBtnColor);
  color: var(--saveBtnColor);
}

.salary__save:hover {
  font-size: 0.9rem;
  font-weight: 600;
  background: var(--saveBtnColor);
  color: var(--textColor);
}

.salary__monthLabel {
  max-width: 150px !important;
  border: none !important;
  margin-right: 5rem;
  text-transform: capitalize;
}

@media screen and (max-width: 1280px) {
  .salary {
    margin-top: 2rem;
  }
}

@media screen and (max-width: 480px) {
  .salary__year > div {
    width: 250px;
    margin-left: 0;
  }
}

@media screen and (max-width: 320px) {
  .salary__year > div {
    width: 200px;
  }
}
