.guestDetails__item {
  display: flex;
  border-top: 1px solid black;
  padding-top: 1rem;
  justify-content: space-between;
}

.guestDetails__itemActions {
  display: flex;
  flex-direction: column;
}

.guestDetails__itemActions > button {
  min-width: 8rem;
  margin-top: 0.5rem;
  padding: 0.4rem 0.8rem;
  font-size: 0.8rem;
  font-weight: 600;
}

.checkout--okay {
  color: var(--checkColor);
  border: 1px solid var(--checkColor);
}

.checkout--okay:hover {
  background-color: var(--checkColor);
  color: #fff;
}

.checkout--error {
  background: rgba(207, 68, 68, 0.5);
  color: #fff;
  cursor: not-allowed;
}

.guestDetails__total {
  margin-top: 1rem;
}

.guestDetails__paid {
  margin-top: 1rem;
}

.guestDetails__paid > h5,
.guestDetails__total > h4 {
  width: 10rem;
  display: inline-block;
}

.guestDetails__paid > span {
  width: 10rem;
  display: inline-block;
  margin-right: 5rem;
  font-size: 1rem;
  font-weight: 500;
}

.guestDetails__total > span {
  width: 10rem;
  display: inline-block;
  margin-right: 5rem;
  font-size: 1.1rem;
  font-weight: 500;
}

.guestDetails__food,
.guestDetails__services {
  display: flex;
  margin-top: 1rem;
  align-items: center;
}

.guestDetails__food > input {
  cursor: pointer;
}

.guestDetails__services > input {
  cursor: pointer;
}

.guestDetails__paymentDate {
  color: var(--titleColor);
  font-size: 0.6rem !important;
  font-weight: 600;
}

.guestDetails__payment > span {
  max-width: 10rem;
  min-width: 7rem;
  display: block;
  margin-right: 1rem;
  font-size: 0.9rem;
}

.guestDetails__paymentDelete {
  width: 13px;
  margin-left: 0.6rem;
  cursor: pointer;
}

.guestDetails__header {
  color: #6389c2;
  font-weight: 700;
  font-size: 0.9rem;
  width: 10rem;
}

.guestDetails__paid {
  margin-top: 1rem;
}

.guestDetails__paid > h5,
.guestDetails__total > h4 {
  width: 10rem;
  display: inline-block;
}

.guestDetails__paid > span {
  width: 10rem;
  display: inline-block;
  margin-right: 5rem;
  font-size: 1rem;
  font-weight: 500;
}

.guestDetails__total > span {
  width: 10rem;
  display: inline-block;
  margin-right: 5rem;
  font-size: 1.1rem;
  font-weight: 500;
}

.guestDetails__final {
  text-align: end;
}

.guestDetails__final span {
  margin: 0;
}

.guestDetails__final > button {
  margin: 0.5rem 0 0.5rem 2rem;
  padding: 0.5rem 1rem;
}

@media screen and (max-width: 420px) {
  .guestDetails__item {
    display: flex;
    flex-direction: column-reverse;
    padding-top: 0;
  }

  .guestDetails__itemActions > button {
    min-width: 3rem;
    font-size: 0.7rem;
  }

  .guestDetails__header {
    color: #6389c2;
    font-weight: 700;
    font-size: 0.9rem;
    width: 10rem !important;
  }

  .guestDetails__paid {
    margin-top: 0.5rem;
  }

  .guestDetails__food,
  .guestDetails__services {
    margin-top: 0.5rem;
  }

  .guestDetails__paid > h5,
  .guestDetails__total > h4 {
    width: 4rem;
    display: inline-block;
  }

  .guestDetails__paid > span {
    width: 4rem;
    display: inline-block;
    margin-right: 5rem;
    font-size: 0.7rem;
    font-weight: 500;
  }

  .guestDetails__total > span {
    width: 4rem;
    display: inline-block;
    margin-right: 5rem;
    font-size: 1rem;
    font-weight: 500;
  }

  .guestDetails__final > button {
    font-size: 0.7rem;
  }

  .guestDetails__checkedOut {
    font-size: 0.9rem;
  }
}
