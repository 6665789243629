.expense__box {
  height: 90%;
  width: 100%;
  background: var(--boxColor);
  border-radius: 7px;
  padding: 1rem 2rem;
}

.expense__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.expense__date > div {
  display: flex;
  margin-bottom: 5px;
  text-align: start;
}

.expense__date > div > label {
  margin-right: 5px;
  width: 48px;
}

.expense__title {
  font-weight: 300;
  margin: 0.5rem 0 0.5rem 0;
}

.expense__filters {
  margin: 0 0 1rem 0;
  text-align: start;
}

.expense__filters > input {
  height: 35px;
  width: 230px;
  max-width: 300px;
  padding-left: 1rem;
  border-radius: 5px;
  margin-right: 2rem;
  border: 1px solid var(--expenseColor);
}

.expense__filters > label {
  font-size: 0.9rem;
  margin-right: 1.5rem;
}

.expense__filters > label > input {
  margin: 0 0.3rem 0 0;
  border: 1px solid var(--expenseColor);
}

.expense__actions {
  padding: 0.4rem;
  border: 1px solid var(--expenseColor);
  border-radius: 5px;
  color: var(--expenseColor);
  cursor: pointer;
}

.expense__actions:hover {
  background: var(--expenseColor);
  color: var(--textColor);
}

.expense__details {
  height: 78%;
  width: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.expense__details::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.expense__details {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media screen and (max-width: 480px) {
  .expense__date > div > label {
    font-size: 0.8rem;
    width: 38px;
  }

  .expense__actions {
    padding: 0.2rem;
    font-size: 0.8rem;
  }

  .expense__box {
    padding: 1rem;
  }
}
