.water {
  height: 75vh;
  width: 100vw;
  background-color: var(--backgroundColor);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 320px) {
  .water {
    height: 71vh;
  }
}
