.notifying {
  height: 90%;
  width: 320px;
  background-color: var(--boxColor);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.notifying > img {
  width: 20%;
  margin-bottom: 1.6vw;
}

.notifying > h3 {
  color: var(--notificationColor);
  margin: 0.3vw 0;
  font-size: 1rem;
}

.notifying__form {
  width: 100%;
  margin-top: 1vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.notifying__formField {
  height: 4.7rem;
}

.notifying__formField > label {
  font-weight: 500;
  font-size: 0.9rem;
  display: block;
}

.notifying__formField > input {
  width: 245px;
  height: 2.35rem;
  border: none;
  background-color: var(--inputColor);
  padding-left: 15px;
  margin: 0.2rem 0;
  border-radius: 4px;
  font-size: 1rem;
}

.notifying__calendar {
  min-width: 245px;
  min-height: 2.35rem;
  border: none;
  border-radius: 4px;
  padding-left: 10px;
  background: var(--inputColor);
}

.notifying__form > button {
  height: 2.5rem;
  width: 30%;
  color: var(--notifyingButtonColor);
  border: 1px solid var(--notifyingButtonColor);
  font-size: 0.9rem;
  font-weight: 600;
  margin-top: 0.2rem;
}

.notifying__form > button:hover {
  background: var(--notifyingButtonColor);
  color: var(--textColor);
}

@media screen and (max-width: 1400px) {
  .notifying {
    width: 400px;
  }
}

@media screen and (max-width: 768px) {
  .notifying {
    width: 450px;
  }
}

@media screen and (max-width: 480px) {
  .notifying {
    width: 290px;
  }

  .notifying > img {
    width: 15%;
    margin-bottom: 1.1rem;
  }

  .notifying > h3 {
    font-size: 0.9rem;
    margin-bottom: 1.5rem;
  }

  .notifying__formField {
    height: 4rem;
    width: 75%;
  }

  .notifying__formField > input {
    width: 225px;
    height: 2rem;
    border: none;
    background-color: var(--inputColor);
    padding-left: 15px;
    margin: 0.2rem 0;
    border-radius: 4px;
    font-size: 1rem;
  }

  .notifying__calendar {
    min-width: 225px;
    min-height: 2rem;
    border: none;
    border-radius: 4px;
    padding-left: 10px;
    background: var(--inputColor);
  }

  .notifying__form > button {
    height: 2rem;
    width: 25%;
    color: var(--notifyingButtonColor);
    border: 1px solid var(--notifyingButtonColor);
    font-size: 0.8rem;
    font-weight: 700;
    margin-top: 0.5rem;
  }
}
