:root {
  --backgroundColor: #b1b1ad;
  --inputColor: #cfcfc1;
  --buttonColor: #0a2d0d;
  --secondaryBackgroundColor: #1a1a1b;
  --textColor: #fff;
  --boxColor: #fff;
  --notifyingButtonColor: #0c8014;
  --saveBtnColor: #0c8014;
  --checkColor: #0c8014;
  --salesColor: #0c8014;
  --profitColor: #0c8014;
  --lossColor: #cf4444;
  --expenseColor: #cf4444;
  --notificationColor: #cf4444;
  --requiredColor: #cf4444;
  --titleColor: #c19433;
  --linkColor: #c19433;
  --cancelColor: #cf4444;
  --errorColor: #cf4444;
  --tableColor: #6389c2;
  --editColor: #6389c2;
  --smsBtnColor: #6389c2;

  --borderRadius: 4px;
}

button {
  cursor: pointer;
  border: none;
  outline-width: 0;
  border-radius: 4px;
  text-transform: uppercase;
}

input {
  outline-width: 0;
}

textarea {
  outline-width: 0;
}

::placeholder {
  font-size: 0.9rem;
  font-weight: 200;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

select {
  outline-width: 0;
}

a {
  text-decoration: none;
}

.app__loading {
  height: 100vh;
  width: 100vw;
  background: rgba(250, 250, 250, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  height: 75vh;
  background: var(--backgroundColor);
  display: flex;
  justify-content: center;
  align-items: center;
}

.container__box {
  height: 90%;
  width: 80%;
  background: var(--boxColor);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  padding: 1rem;
}

.container__box > h4 {
  text-transform: uppercase;
  color: var(--titleColor);
  margin: 1rem 0;
}

.g-edit__btn {
  border: 1px solid var(--editColor);
  color: var(--editColor);
}

.g-edit__btn:hover {
  background: var(--editColor);
  color: #fff;
}

.g-delete__btn {
  border: 1px solid var(--cancelColor);
  color: var(--cancelColor);
}

.g-delete__btn:hover {
  background: var(--cancelColor);
  color: #fff;
}

.g-success__btn {
  border: 1px solid var(--checkColor);
  color: var(--checkColor);
}

.g-success__btn:hover {
  background: var(--checkColor);
  color: #fff;
}

/* Table List Css */

.g-list__box {
  height: 90%;
  width: 80%;
  background: var(--boxColor);
  border-radius: var(--borderRadius);
  padding: 1rem 2rem;
}

.g-list__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.g-list__title {
  font-weight: 300;
  margin: 0.5rem 0 0.5rem 0;
}

.g-list__actions {
  padding: 0.4rem;
  border: 1px solid var(--tableColor);
  border-radius: var(--borderRadius);
  color: var(--tableColor);
  cursor: pointer;
}

.g-list__actions:hover {
  background: var(--tableColor);
  color: var(--textColor);
}

.g-list__filters {
  margin: 0 0 1rem 0;
  text-align: start;
}

.g-list__filters > input {
  height: 35px;
  width: 230px;
  max-width: 300px;
  padding-left: 1rem;
  border-radius: var(--borderRadius);
  margin-right: 2rem;
  margin-bottom: 0.5rem;
  border: 1px solid var(--tableColor);
}

.g-list__filters > label {
  font-size: 0.9rem;
  margin-right: 1.5rem;
  display: inline-flex;
  align-items: center;
}

.g-list__filters > label > input {
  margin: 0 0.3rem 0 0;
  border: 1px solid var(--tableColor);
}

.g-list__details {
  height: 78%;
  width: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.g-list__details::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.g-list__details {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Form */
.form__text {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  width: 300px;
}

.form__text > label {
  margin-bottom: 0.2rem;
  font-size: 0.8rem;
  font-weight: 500;
}

.form__text > input {
  width: 100%;
  height: 35px;
  border: none;
  border-radius: 4px;
  padding-left: 10px;
  background: var(--inputColor);
}

.form__text > textarea {
  width: 100%;
  border: none;
  border-radius: 4px;
  padding-top: 10px;
  padding-left: 10px;
  background: var(--inputColor);
}

.form__checkbox {
  margin-top: 0.5rem;
}

.form__checkbox > input {
  margin-right: 10px;
}

.form__calendar {
  min-width: 300px;
  min-height: 35px;
  border: none;
  border-radius: 4px;
  padding-left: 10px;
  background: var(--inputColor);
  font-size: 0.8rem;
}

.form__button--add {
  margin-top: 1rem;
  height: 2.8rem;
  width: 8rem;
  font-size: 0.9rem;
  font-weight: 600;
  border: 1px solid var(--saveBtnColor);
  color: var(--saveBtnColor);
}

.form__button--add:hover {
  font-size: 0.9rem;
  font-weight: 600;
  background: var(--saveBtnColor);
  color: var(--textColor);
}

.form__error {
  display: block;
  font-size: 0.8rem;
  color: var(--errorColor);
}

/* Loading */
.loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Toastify__toast--success {
  background: var(--saveBtnColor);
}

.Toastify__toast--warning {
  background: var(--titleColor);
}

.Toastify__toast--error {
  background: var(--errorColor);
}

.g-datePicker > input {
  height: 25px;
  max-width: 300px;
  padding-left: 0.5rem;
  border-radius: var(--borderRadius);
  border: 1px solid var(--tableColor);
}

/* Tablets */

@media screen and (max-width: 1280px) {
  .container__box {
    width: 90%;
  }

  ::placeholder {
    font-size: 0.7rem !important;
  }
}

/* small tablets */
@media screen and (max-width: 768px) {
  ::placeholder {
    font-size: 0.8rem !important;
  }

  .g-list__box {
    height: 95%;
    width: 90%;
    padding: 0.7rem;
  }

  .g-list__details {
    justify-content: flex-start;
    padding-bottom: 1rem;
  }
}

/* Mobile */
@media screen and (max-width: 480px) {
  .container__box {
    width: 90%;
    justify-content: flex-start;
  }

  ::placeholder {
    font-size: 0.7rem !important;
  }

  .g-list__title {
    font-size: 0.9rem;
    font-weight: 400;
    margin: 0.5rem 0 0.5rem 0;
  }

  .g-list__actions {
    font-size: 0.8rem;
    padding: 0.3rem;
  }

  .g-list__filters {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 0.5rem;
    text-align: start;
  }

  .g-list__filters > input {
    height: 25px;
    width: 150px;
    max-width: 200px;
    padding-left: 0.5rem;
    margin-right: 0.5rem;
    border: 1px solid var(--tableColor);
    font-size: 0.7rem;
  }

  .g-list__filters > label {
    font-size: 0.7rem;
    margin-right: 0.6rem;
  }

  .g-list__filters > label > input {
    margin-right: 0.1rem;
  }

  .g-datePicker > input {
    height: 23px;
    max-width: 150px;
    padding-left: 0.5rem;
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 320px) {
  .form__text {
    width: 250px;
  }

  .form__calendar {
    min-width: 250px;
  }

  .container {
    height: 71vh;
  }
}
