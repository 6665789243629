table {
  width: 100%;
  max-height: 100px;
  font-size: 0.85rem;
  border-collapse: collapse;
}

table thead th {
  position: sticky;
  top: -1px;
  z-index: 1;
}

table td,
table th {
  padding: 0.6rem 0.6rem;
}

table tr {
  border: 1px solid var(--tableColor);
  cursor: pointer;
}

table tr:hover {
  background-color: var(--tableColor);
  color: #fff;
}

table th {
  text-align: start;
  background-color: var(--tableColor);
  color: white;
}

.actions__icon {
  height: 16px;
  padding: 0 1rem;
}

.table__actions:hover {
  background: #fff;
}

.table__checkIcon {
  height: 16px;
}

.table__room {
  margin-right: 5px;
  font-weight: 500;
  color: var(--titleColor);
}

@media screen and (max-width: 480px) {
  table {
    font-size: 0.7rem;
  }

  table td,
  table th {
    padding: 0.4rem 0.4rem;
  }

  .actions__icon {
    height: 13px;
    margin-right: 0.5rem;
  }

  .actions__icon:first-child {
    margin-bottom: 0.2rem;
  }
}

.tableDeleted tr {
  border: 1px solid var(--errorColor);
}

.tableDeleted tr:hover {
  background-color: var(--errorColor);
}

.tableDeleted th {
  background-color: var(--errorColor);
}
