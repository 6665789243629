.inventory {
  height: 75vh;
  background: var(--backgroundColor);
  display: flex;
  justify-content: center;
  align-items: center;
}

.inventory__box {
  height: 90%;
  width: 95%;
  background: var(--boxColor);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  position: relative;
}

.inventory__title {
  margin: 1rem 0;
  color: var(--titleColor);
  text-transform: uppercase;
}

.inventory__groups {
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.inventory__advance {
  flex: 4;
  text-align: center;
}

.inventory__cost {
  flex: 4;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .inventory__box {
    height: 95%;
  }

  .inventory__groups {
    flex-direction: column;
    align-items: center;
  }

  .inventory__advance {
    margin-top: 350px;
  }
}

@media screen and (max-width: 480px) {
  .inventory__groups {
    align-items: flex-start;
  }

  .inventory__advance {
    margin-top: 250px;
  }
}

@media screen and (max-width: 320px) {
  .inventory {
    height: 71vh;
  }
}
