.capitalInvestmentList {
  height: 75vh;
  background: var(--backgroundColor);
  display: flex;
  justify-content: center;
  align-items: center;
}

.capitalInvestmentAdd {
  height: 75vh;
  background: var(--backgroundColor);
  display: flex;
  justify-content: center;
  align-items: center;
}

.capitalInvestmentEdit {
  height: 75vh;
  background: var(--backgroundColor);
  display: flex;
  justify-content: center;
  align-items: center;
}

.capitalInvestmentList__total {
  margin-bottom: 5px;
  text-align: end;
  color: var(--titleColor);
}

.capitalInvestmentList__details {
  height: 70%;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

@media screen and (max-width: 480px) {
  .capitalInvestmentList__total {
    font-size: 0.75rem;
    font-weight: 500;
  }

  .capitalInvestmentList__details {
    align-items: flex-start;
  }
}

@media screen and (max-width: 320px) {
  .capitalInvestmentList {
    height: 71vh;
  }

  .capitalInvestmentAdd {
    height: 71vh;
  }

  .capitalInvestmentEdit {
    height: 71vh;
  }
}
