.profile {
  height: 75vh;
  background: var(--backgroundColor);
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile__box {
  background: var(--boxColor);
  height: 90%;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: var(--borderRadius);
  padding: 1rem;
}

.profile__content {
  overflow: auto;
}

.profile__title {
  color: var(--titleColor);
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
}

.profile__group {
  margin-top: 0.5rem;
}

.profile__group > h5 {
  width: 10rem;
  display: inline-block;
}

.profile__group > span {
  max-width: 30rem;
  min-width: 5rem;
  display: inline-block;
  margin-right: 1rem;
  font-size: 0.9rem;
}

.profile__group > input {
  min-height: 28px;
  padding-left: 5px;
  border: 1px solid var(--titleColor);
}

.profile__changePasswordBtn {
  margin-top: 1rem;
  padding: 10px;
  border: 1px solid var(--editColor);
  color: var(--editColor);
}

.profile__changePasswordBtn:hover {
  background: var(--editColor);
  color: #fff;
}

.profile__updatePasswordBtn {
  margin-top: 1rem;
  padding: 10px;
  border: 1px solid var(--saveBtnColor);
  color: var(--saveBtnColor);
  margin-right: 1rem;
}

.profile__updatePasswordBtn:hover {
  background: var(--saveBtnColor);
  color: #fff;
}

.profile__backBtn {
  margin-top: 1rem;
  padding: 10px;
  border: 1px solid var(--cancelColor);
  color: var(--cancelColor);
}

.profile__backBtn:hover {
  background: var(--cancelColor);
  color: #fff;
}

.profile__form {
  margin-top: 3rem;
  padding: 1rem;
  border-radius: var(--borderRadius);
  border: 1px solid var(--titleColor);
}

@media screen and (max-width: 320px) {
  .profile {
    height: 71vh;
  }
}
