.monthly {
  height: 75vh;
  background: var(--backgroundColor);
  display: flex;
  justify-content: center;
  align-items: center;
}

.monthly__box {
  height: 90%;
  width: 95%;
  background: var(--boxColor);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  position: relative;
}

.monthly__box > h4 {
  text-transform: uppercase;
  color: var(--titleColor);
}

.monthly__details {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 1rem 0;
}

.monthly__date {
  display: flex;
}

.monthly__startDate {
  margin-right: 2rem;
  display: flex;
}

.monthly__endDate {
  display: flex;
}

.monthly__startDate > label {
  margin-right: 5px;
}

.monthly__endDate > label {
  margin-right: 5px;
}

.monthly__month {
  width: 120px;
  font-size: 0.8rem;
}

.monthly__detailsBtn {
  padding: 0.6rem;
  font-size: 0.8rem;
  text-transform: uppercase;
}

.monthly__detailsBtn--sales {
  border: 1px solid rgba(75, 192, 192, 1);
  color: rgba(75, 192, 192, 1);
}

.monthly__detailsBtn--sales:hover {
  background: rgba(75, 192, 192, 1);
  color: var(--textColor);
}

.monthly__detailsBtn--expense {
  border: 1px solid rgb(255, 99, 132);
  color: rgb(255, 99, 132);
}

.monthly__detailsBtn--expense:hover {
  background: rgb(255, 99, 132);
  color: var(--textColor);
}

.monthly__chart {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .monthly__details {
    flex-direction: column;
  }

  .monthly__date {
    flex-direction: column;
  }

  .monthly__startDate {
    display: block;
  }

  .monthly__endDate {
    display: block;
    margin-bottom: 1rem;
  }

  .monthly__startDate > label {
    font-size: 0.7rem;
    width: 2rem;
    margin: 0;
  }

  .monthly__endDate > label {
    font-size: 0.7rem;
    width: 2rem;
    margin: 0;
  }

  .monthly__detailsBtn {
    width: 13rem;
  }

  .monthly__chart {
    width: 95%;
  }
}

@media screen and (max-width: 320px) {
  .monthly {
    height: 71vh;
  }
}
