.utilities {
  height: 75vh;
  width: 100vw;
  background: var(--backgroundColor);
  display: grid;
  align-content: center;
  grid-template-columns: auto auto auto auto auto auto;
  align-items: center;
  gap: 1rem;
  justify-content: center;
}

.utilities__item {
  position: relative;
  height: 8.5rem;
  width: 8.5rem;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border-radius: 7px;
  padding: 0.5rem;
}

.utilities__item > img {
  width: 60%;
}

.utilities__item > span {
  font-size: 1rem;
  font-weight: 600;
}

.utilities__countDays {
  position: absolute;
  background: var(--notificationColor);
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -10px;
  right: -10px;
}

.utilities__countDays > span {
  color: var(--textColor);
  font-size: 0.8rem;
  font-weight: 700;
}

@media screen and (max-width: 1400px) {
  .utilities__item {
    height: 7rem;
    width: 7rem;
  }
}

@media screen and (max-width: 768px) {
  .utilities {
    grid-template-columns: auto auto auto auto;
  }

  .utilities__item {
    height: 6rem;
    width: 6rem;
  }
}

@media screen and (max-width: 480px) {
  .utilities {
    grid-template-columns: auto auto auto auto;
  }

  .utilities__item {
    height: 3.8rem;
    width: 3.8rem;
  }

  .utilities__item > span {
    font-size: 0.55rem;
    font-weight: 500;
    letter-spacing: 0.2px;
  }

  .utilities__countDays {
    width: 1.2rem;
    height: 1.2rem;
    top: -6.5px;
    right: -6.5px;
  }

  .utilities__countDays > span {
    color: var(--textColor);
    font-size: 0.55rem;
    font-weight: 600;
  }
}

@media screen and (max-width: 320px) {
  .utilities {
    height: 71vh;
    grid-template-columns: auto auto auto auto;
  }

}
