.policiesEdit {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.policiesEdit__editor {
  margin-top: 2rem;
  width: 80vw;
}

.policiesEdit__btn {
    margin-top: 1rem;
    padding: 1rem;
}
