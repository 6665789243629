.creditRecordList {
  height: 75vh;
  background: var(--backgroundColor);
  display: flex;
  justify-content: center;
  align-items: center;
}

.creditRecordAdd {
  height: 75vh;
  background: var(--backgroundColor);
  display: flex;
  justify-content: center;
  align-items: center;
}

.creditRecordEdit {
  height: 75vh;
  background: var(--backgroundColor);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 320px) {
  .creditRecordList {
    height: 71vh;
  }

  .creditRecordAdd {
    height: 71vh;
  }

  .creditRecordEdit {
    height: 71vh;
  }
}
