.rooms {
  height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rooms__floor {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.rooms__box {
  height: 9rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 0.8rem;
}

.rooms__box:nth-child(5) {
  grid-column: span 2;
}

.rooms__guestID {
  font-size: 0.9rem;
  font-weight: 700;
  margin-bottom: 0.2rem;
  color: var(--notificationColor);
  margin-left: 5px;
}

.rooms__bookID {
  margin-left: 0.5rem;
  color: #f1990a;
  font-size: 0.8rem;
}

.room {
  position: relative;
  height: 7rem;
  width: 7rem;
  margin-right: 1rem;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.room--free {
  background: #0d7815;
}

.room--occupied {
  background: #cf4444;
  cursor: pointer;
}

.room > h2 {
  color: var(--textColor);
}

.rooms__reserved {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -10px;
  right: -10px;
  background: #f1990a;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 2px;
}

.rooms__reserved > h4 {
  color: #fff;
  cursor: pointer;
}

@media screen and (max-width: 1400px) {
  .rooms__box {
    height: 7rem;
  }

  .rooms__guestID {
    font-size: 0.75rem;
    font-weight: 700;
  }

  .rooms__bookID {
    font-size: 0.7rem;
  }

  .room {
    height: 5.5rem;
    width: 5.5rem;
  }

  .room > h2 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .rooms {
    padding: 1rem;
  }

  .rooms__box {
    height: 7rem;
  }

  .rooms__guestID {
    display: flex;
    flex-direction: column;
    font-size: 0.8rem;
    font-weight: 700;
  }

  .rooms__bookID {
    margin-left: 0;
    color: #f1990a;
    font-size: 0.7rem;
  }

  .room {
    height: 4rem;
    width: 4rem;
  }

  .rooms__reserved {
    top: -4px;
    right: -4px;
    width: 1.3rem;
    height: 1.3rem;
  }

  .rooms__reserved > h4 {
    font-size: 0.8rem;
    font-weight: 500;
  }

  .room > h2 {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 480px) {
  .rooms {
    height: 70vh;
  }

  .rooms__box {
    height: 5rem;
    width: 3rem;
  }

  .rooms__guestID {
    font-size: 0.6rem;
    font-weight: 500;
  }

  .rooms__bookID {
    font-size: 0.5rem;
  }

  .room {
    height: 2.6rem;
    width: 2.6rem;
  }

  .rooms__reserved {
    width: 0.8rem;
    height: 0.8rem;
  }

  .rooms__reserved > h4 {
    font-size: 0.5rem;
    font-weight: 500;
  }

  .room > h2 {
    font-size: 0.8rem;
  }
}
