.login {
  height: 100vh;
  min-height: 640px;
  width: 100vw;
  background-color: var(--backgroundColor);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.login__box {
  height: 90%;
  width: 25%;
  background-color: #fff;
  border-radius: var(--borderRadius);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2.5rem;
}

.login__logo {
  height: 10vw;
  width: 10vw;
  min-height: 8rem;
  min-width: 8rem;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid var(--titleColor);
}

.login__hotel {
  margin-top: 1rem;
  color: var(--titleColor);
  text-transform: uppercase;
}

.login__title {
  margin-bottom: 2.5rem;
  color: var(--titleColor);
}

.login__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.login__formField {
  height: 2.5rem;
  width: 100%;
  margin-top: 0.8rem;
}

.login__formField > input {
  height: 100%;
  width: 100%;
  font-size: 1rem;
  padding-left: 20px;
  background: var(--inputColor);
  border: none;
  border-radius: var(--borderRadius);
}

.login__form > button {
  height: 2.8rem;
  width: 9vw;
  margin-top: 1.5rem;
  border: 1px solid var(--buttonColor);
  color: var(--buttonColor);
  font-size: 1rem;
  font-weight: 700;
  border-radius: var(--borderRadius);
  outline: none;
}

.login__form > button:hover {
  background: var(--buttonColor);
  color: #fff;
}

@media screen and (max-width: 1200px) {
  .login__box {
    min-width: 480px;
    padding: 3rem;
  }

  .login__form > button {
    height: 2.4rem;
    width: 100px;
  }
}

@media screen and (max-width: 768px) {
  .login__box {
    padding: 1.2rem;
  }
}

@media screen and (max-width: 480px) {
  .login__box {
    min-width: 290px;
    padding: 1.4rem;
  }

  .login__logo {
    min-height: 7.5rem;
    min-width: 7.5rem;
  }
}

@media screen and (max-width: 320px) {
  .login {
    min-height: 500px;
  }

  .login__logo {
    min-height: 6.5rem;
    min-width: 6.5rem;
  }
}
