.guestDetails {
  height: 75vh;
  background: var(--backgroundColor);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.guestDetails__box {
  background: var(--boxColor);
  height: 90%;
  width: 90%;
  padding: 1.5rem 6rem;
  border: 2px solid #6389c2;
  border-top: 0.5rem solid #6389c2;
  border-radius: 5px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.guestDetails__title {
  color: #6389c2;
  font-weight: 400;
  font-size: 1.4rem;
  margin-bottom: 2rem;
}

.guestDetails__content {
  display: flex;
  justify-content: center;
}

.guestDetails__personalInfo {
  display: flex;
  padding-bottom: 1rem;
  justify-content: space-between;
}

.guestDetails__group {
  margin-top: 1rem;
  display: flex;
}

.guestDetails__group > h5,
.guestDetails__group > h4 {
  width: 10rem;
  display: inline-block;
}

.guestDetails__group > span {
  max-width: 10rem;
  min-width: 5rem;
  margin-right: 1rem;
  font-size: 0.9rem;
}

.guestDetails__identification {
  cursor: pointer;
  max-width: 33%;
  max-height: 500px;
}

.guestDetails__identification:hover {
  transform: scale(1.8);
  transform-origin: right top;
  transition: 0.5s ease-in-out;
  transition-delay: 0.5s;
}

.guestDetails__actions {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
}

.guestDetails__actions > button {
  min-width: 10rem;
  margin-top: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  font-weight: 600;
}

.guestDetails--badGuest {
  border: 5px solid var(--cancelColor);
  border-top: 1.2rem solid var(--cancelColor);
}

.guestDetails__badGuestBtn--bad {
  background: var(--cancelColor);
  color: var(--textColor);
}

.guestDetails__badGuestBtn--good {
  border: 1px solid var(--cancelColor);
  color: var(--cancelColor);
}

.guestDetails__badGuestBtn--good:hover {
  background: var(--cancelColor);
  color: var(--textColor);
}

@media screen and (max-width: 768px) {
  .guestDetails__content {
    display: flex;
    flex-direction: column-reverse;
  }

  .guestDetails__actions {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    margin-bottom: 1rem;
  }
  
  .guestDetails__personalInfo {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 480px) {
  .guestDetails__box {
    padding: 1rem;
  }

  .guestDetails__title {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }

  .guestDetails__content {
    display: flex;
    flex-direction: column-reverse;
  }

  .guestDetails__actions {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    margin-bottom: 1rem;
  }

  .guestDetails__actions > button {
    min-width: 3rem;
    width: 100%;
    font-size: 0.7rem;
  }

  .guestDetails__personalInfo {
    display: flex;
    flex-direction: column-reverse;
  }

  .guestDetails__identification {
    max-width: 50%;
    margin-bottom: 1rem;
  }

  .guestDetails__identification:hover {
    transform-origin: left top;
  }

  .guestDetails__group {
    margin-top: 0.5rem;
  }

  .guestDetails__group > h5,
  .guestDetails__group > h4 {
    width: 4.5rem;
    display: inline-block;
    font-size: 0.75rem;
  }

  .guestDetails__group > span {
    max-width: 4rem;
    min-width: 3rem;
    margin-right: 0.5rem;
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 320px) {
  .guestDetails {
    height: 71vh;
  }
}
