.guestEdit {
  height: 75vh;
  background: var(--backgroundColor);
  display: flex;
  justify-content: center;
  align-items: center;
}

.guestEdit__box {
  height: 90%;
  width: 80%;
  background: var(--boxColor);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.guestEdit__title {
  text-transform: uppercase;
  color: var(--titleColor);
  margin: 1rem 0;
}

.showGuestId {
  height: 50px;
}

@media screen and (max-width: 480px) {
  .guestEdit__box {
    width: 90%;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 320px) {
  .guestEdit {
    height: 71vh;
  }
}
