.advanceEdit {
  height: 75vh;
  background: var(--backgroundColor);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 320px) {
  .advanceEdit {
    height: 71vh;
  }
}
