.frontReservation {
  height: 75vh;
  background: var(--backgroundColor);
  display: flex;
  justify-content: center;
  align-items: center;
}

.frontReservation__box {
  background: var(--boxColor);
  height: 90%;
  width: 70%;
  padding: 2rem 3rem;
  overflow: auto;
  border-radius: var(--borderRadius);
}

.frontReservation__header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.frontReservation__title {
  color: #6389c2;
  font-weight: 400;
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}

.frontReservation__actions > button {
  height: 1.8rem;
  width: 5.5rem;
  margin-right: 1rem;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;
}

.frontReservation__group {
  margin-top: 0.5rem;
}

.frontReservation__group > h5 {
  width: 10rem;
  display: inline-block;
}

.frontReservation__group > span {
  max-width: 30rem;
  min-width: 5rem;
  display: inline-block;
  margin-right: 1rem;
  font-size: 0.9rem;
}

@media screen and (max-width: 480px) {
  .frontReservation__box {
    height: 90%;
    width: 90%;
    padding: 1rem;
    font-size: 0.8rem;
  }

  .frontReservation__title {
    font-size: 1.2rem;
  }

  .frontReservation__actions > button {
    height: 1.5rem;
    width: 4rem;
    margin-right: 0.7rem;
    font-size: 0.6rem;
    margin-bottom: 0.5rem;
  }

  .frontReservation__group > h5 {
    width: 6rem;
  }

  .frontReservation__group > span {
    min-width: 5rem;
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 320px) {
  .frontReservation {
    height: 71vh;
  }
}
