.rent {
  height: 75vh;
  background: var(--backgroundColor);
  display: flex;
  justify-content: center;
  align-items: center;
}

.rent__box {
  height: 90%;
  width: 80%;
  background: var(--boxColor);
  border-radius: 7px;
}

.rent__title {
  height: 10%;
  width: 100%;
  padding: 0 3rem;
  background: var(--titleColor);
  color: var(--textColor);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 7px 7px 0 0;
}

.rent__title > h4 {
  font-size: 1.2rem;
}

.rent__addBtn {
  padding: 0.4rem;
  border: 1px solid var(--textColor);
  border-radius: 5px;
  color: var(--textColor);
  cursor: pointer;
}

.rent__addBtn:hover {
  background: var(--textColor);
  color: var(--titleColor);
}

.rent__body {
  padding: 1rem 2rem;
  height: 90%;
}

.rent__year {
  width: 40%;
}

.rent__year > div {
  display: inline-block;
  width: 150px;
  margin-left: 20px;
}

.rent__months {
  width: 100%;
  height: 85%;
  overflow: auto;
  padding: 1rem;
}

.rent__itemGroup {
  display: flex;
  align-items: center;
  height: 2rem;
  margin-top: 1rem;
}

.rent__itemGroup > span {
  width: 200px;
}

.rent__paid {
  flex: 2;
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.rent__paid > input {
  margin-right: 5px;
}

.rent__itemGroup > input {
  flex: 3;
  height: 100%;
  border-radius: 4px;
  border: 1px solid var(--tableColor);
  padding-left: 1rem;
  margin-right: 1rem;
}

.rent__save {
  margin-top: 1rem;
  height: 2.8rem;
  width: 8rem;
  font-size: 0.9rem;
  font-weight: 600;
  border: 1px solid var(--saveBtnColor);
  color: var(--saveBtnColor);
}

.rent__save:hover {
  font-size: 0.9rem;
  font-weight: 600;
  background: var(--saveBtnColor);
  color: var(--textColor);
}

.rent__monthLabel {
  max-width: 150px !important;
  border: none !important;
  margin-right: 5rem;
  text-transform: capitalize;
}

@media screen and (max-width: 480px) {
  .rent__box {
    width: 90%;
  }

  .rent__title {
    padding: 0 1.5rem;
  }

  .rent__title > h4 {
    font-size: 1rem;
  }

  .rent__year > div {
    display: inline-block;
    width: 280px;
    margin: 0;
  }

  .rent__year > label {
    font-size: 0.8rem;
  }

  .rent__itemGroup {
    margin-top: 0;
    font-size: 0.8rem;
  }

  .rent__itemGroup > input {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 320px) {
  .rent {
    height: 71vh;
  }

  .rent__year > div {
    width: 240px;
  }
}
