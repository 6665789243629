.staffDetails {
  height: 75vh;
  background: var(--backgroundColor);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.staffDetails__box {
  background: var(--boxColor);
  height: 90%;
  width: 90%;
  padding: 3rem;
  border-radius: 5px;
  overflow: auto;
  display: flex;
  justify-content: space-evenly;
}

.staffDetails__title {
  color: #6389c2;
  font-weight: 400;
  font-size: 1.4rem;
  margin-bottom: 2rem;
}

@media screen and (max-width: 1280px) {
  .staffDetails__box {
    flex-direction: column;
    justify-content: flex-start;
  }
}
