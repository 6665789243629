.expensesMonthlyList {
  height: 75vh;
  background: var(--backgroundColor);
  display: flex;
  justify-content: center;
  align-items: center;
}

.expensesMonthlyList__box {
  height: 90%;
  width: 80%;
  background: var(--boxColor);
  border-radius: var(--borderRadius);
  padding: 1rem 2rem;
}

.expensesMonthlyList__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.expensesMonthlyList__title {
  font-weight: 300;
  margin: 0.5rem 0 0.5rem 0;
}

.filter__date {
  display: flex;
}

.filter__startDate {
  margin-right: 2rem;
}
