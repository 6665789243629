.header {
  width: 100%;
  min-height: 80px;
  height: 10vh;
  background-color: var(--secondaryBackgroundColor);
  color: var(--textColor);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 1rem;
}

.header__profile > img {
  height: 25px;
  cursor: pointer;
}

.header__logo > img {
  width: 300px;
}

.header__userAdd {
  margin-right: 2rem;
}

.header__userAdd > img {
  height: 25px;
  cursor: pointer;
}

.header__userProfile > img {
  height: 25px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .header__logo > img {
    width: 250px;
  }
}

@media screen and (max-width: 480px) {
  .header__logo > img {
    width: 170px;
  }

  .header__profile > img {
    height: 20px;
  }

  .header__userAdd {
    margin-right: 1.8rem;
  }

  .header__userAdd > img {
    height: 20px;
  }

  .header__userProfile > img {
    height: 20px;
    cursor: pointer;
  }
}
