.modal {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background: rgb(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.SMSModal {
  height: 50%;
  width: 50%;
  background: var(--boxColor);
  border-radius: var(--borderRadius);
  padding: 2rem;
  position: relative;
}

.SMSModal__cancelBtn {
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 20px;
  cursor: pointer;
}

.SMSModal__title {
  color: var(--smsBtnColor);
  font-weight: 400;
  font-size: 1.4rem;
}

.SMSModal__description {
  width: 100%;
  max-height: 25vh;
  padding: 1rem;
}

.SMSModal__sendBtn {
  display: block;
  min-width: 10rem;
  margin-top: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--smsBtnColor);
  border: 1px solid var(--smsBtnColor);
}

.SMSModal__sendBtn:hover {
  background: var(--smsBtnColor);
  color: var(--textColor);
}

.SMSModal__saveBtn {
  display: block;
  min-width: 10rem;
  margin-top: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--saveBtnColor);
  border: 1px solid var(--saveBtnColor);
}

.SMSModal__saveBtn:hover {
  background: var(--saveBtnColor);
  color: var(--textColor);
}

.SMSModal__header {
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 480px) {
  .SMSModal {
    height: 50%;
    width: 90%;
  }

  .SMSModal__sendBtn {
    min-width: 5rem;
    margin-top: 0.5rem;
    padding: 0.5rem 0.5rem;
    font-size: 0.7rem;
  }

  .SMSModal__saveBtn {
    min-width: 5rem;
    padding: 0.5rem 0.5rem;
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 320px) {
  .SMSModal {
    height: 60%;
    width: 90%;
  }
}
