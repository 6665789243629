.functions {
  position: fixed;
  bottom: 0;
  min-height: 80px;
  height: 15vh;
  width: 100%;
  background-color: var(--secondaryBackgroundColor);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  z-index: 10;
}

.functions__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  position: relative;
  cursor: pointer;
}

.functions__item:hover {
  transform: scale(1.2);
}

.functions__countDays {
  position: absolute;
  background: var(--notificationColor);
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -15px;
  right: 0;
}

.functions__countDays > span {
  color: var(--textColor);
  font-size: 0.8rem;
  font-weight: 700;
}

.functions__item > img {
  width: 25px;
}

.functions__item > span {
  font-size: 0.8rem;
  margin-top: 0.4rem;
  display: block;
  text-align: center;
  color: var(--textColor);
}

.functions__item.active > span {
  color: var(--linkColor);
}

.functions__sidebarIcon {
  width: 30px;
  cursor: pointer;
}

.functions__sidebarActions {
  background: var(--secondaryBackgroundColor);
  position: absolute;
  top: -90vh;
  bottom: 15vh;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding-top: 20vh;
}

.functions__sm {
  position: fixed;
  bottom: 0;
  height: 15vh;
  width: 100%;
  background-color: var(--secondaryBackgroundColor);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  z-index: 10;
}

@media screen and (max-width: 1400px) {
  .functions__item > span {
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 768px) {
  .functions__item > img {
    width: 30px;
  }
}
