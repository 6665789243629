.daily {
  height: 75vh;
  background: var(--backgroundColor);
  display: flex;
  justify-content: center;
  align-items: center;
}

.daily__box {
  height: 90%;
  width: 95%;
  background: var(--boxColor);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  position: relative;
}

.daily__title {
  margin: 1rem 0;
  color: var(--titleColor);
  text-transform: uppercase;
}

.daily__groups {
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.daily__sales {
  flex: 4;
  text-align: center;
}

.daily__result {
  margin: 0.5rem 0;
  text-transform: uppercase;
}

.daily__result--profit {
  color: var(--profitColor);
}

.daily__result--loss {
  color: var(--lossColor);
}

.daily__chart {
  width: 260px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.daily__expenses {
  flex: 4;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .daily__box {
    height: 95%;
  }

  .daily__groups {
    flex-direction: column;
    align-items: center;
  }

  .daily__chart {
    position: absolute;
    top: 0;
    left: 250px;
  }

  .daily__sales {
    margin-top: 350px;
  }
}

@media screen and (max-width: 480px) {
  .daily__chart {
    width: 200px;
    left: 80px;
  }

  .daily__groups {
    align-items: flex-start;
  }

  .daily__sales {
    margin-top: 250px;
  }
}

@media screen and (max-width: 320px) {
  .daily {
    height: 71vh;
  }

  .daily__chart {
    left: 50px;
  }
}
